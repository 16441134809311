export const environment = {
    production:false,
    useEmulators:false,
    firebase : {
        apiKey: "AIzaSyD8ZAffdEQC6MivukpsIYDzP6mDYGOZueQ",
        authDomain: "narravero-eu-test.firebaseapp.com",
        projectId: "narravero-eu-test",
        storageBucket: "narravero-eu-test.appspot.com",
        messagingSenderId: "251066406205",
        appId: "1:251066406205:web:287d401d1c4f68c2362154",
        measurementId: "G-FVG7HQ4ZEN"
    },
    tagHostName:"https://nvtest.eu/",
    urlHostName:"api.narravero.it"

}
