import { Component } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { SettingsService } from '../service/settings.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { defaultFields, defaultFieldsTypes } from './../../../../backend/src/defaultFieldsTaskTemplates.dto'
import { TaskTemplateEditSectionDialogComponent } from '../task-template-edit-section-dialog/task-template-edit-section-dialog.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { WorkTaskTemplateDialogComponent } from '../work-task-template-dialog/work-task-template-dialog.component';
import { CommentsService } from '../service/comments.service';
import { TaskTemplateFieldDialogComponent } from '../task-template-field-dialog/task-template-field-dialog.component';
import { WorkTaskTemplatesService } from '../service/work-task-templates.service';
import { deepCopy, removeUndefinedValuesFromObject, supportUrlStructure } from '../../../../backend/utils/object';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { WorkTaskTemplateAdvancedDialogComponent } from '../work-task-template-advanced-dialog/work-task-template-advanced-dialog.component';
import { CancelChangesDialogComponent } from '../cancel-changes-dialog/cancel-changes-dialog.component';
import { Subscription, Observable } from 'rxjs';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { deleteField } from '@angular/fire/firestore';
import { CanComponentDeactivate } from '../can-deactivate/can-deactivate.guard';
import { isDate, isTime } from '../../../../backend/src/filter';
import * as MarkdownIt from 'markdown-it';

@Component({
  selector: 'app-task-template-item',
  templateUrl: './task-template-item.component.html',
  styleUrls: ['./task-template-item.component.css']
})
export class TaskTemplateItemComponent implements CanComponentDeactivate{
  routerUrl:any
  idToShow:any
  taskTemplate:any
  titleTask:string
  taskTemplateGroupName:string
  oldData:any
  sections:any = []
  taskTemplateForm!:FormGroup
  taskTemplateGroups:any
  fieldTypes = defaultFieldsTypes
  tasktemplateUserRights_update:any
  fakeForm:any
  tasktemplateUserRights_delete:any
  phoneFullHeightView = false;
  changesToSave=false
  emptyList=false
  private routerSubscription: Subscription;
  changesCanceled = false
  allKeys:any = []
  allIds:any=[]
  duplicateKeys = false
  addedFields:any = 0
  fieldTypeTitles: { [key: string]: string } = {
    barcode: 'Barcode Field',
    signature: 'Signature Field',
    boolean: 'Boolean Field',
    currency: 'Currency Field',
    dateTime: 'DateTime Field',
    decimal: 'Decimal Field',
    display: 'Display Field',
    document: 'Document Field',
    hidden: 'Hidden Field',
    longText: 'Long Text Field',
    number: 'Number Field',
    text: 'Text Field',
    tridy: 'Tridy Field',
    options: 'Options Field',
    weight: 'Weight Field',
    nfc: 'NFC Field',
    showText:'Display Text Field',
    showImage:'Display Text Field'
  };
  allContentTypesList:any
  missingContentType = false
  selectedContentType:string
  elementTypes:any = [
/*     {//display
      type: 'display',
      icon:'visibility',
      translation:"taskTemplates.add_display"
    }, */
    {//hidden
      type: 'hidden',
      icon:'visibility_off',
      translation:"taskTemplates.add_hidden"
    },
    {//text
      type: 'text',
      icon:'text_format',
      translation:"taskTemplates.add_text"
    },
    {//longText
      type: 'longText',
      icon:'subject',
      translation:"taskTemplates.add_longText"
    },
    {//number
      type: 'number',
      icon:'pin',
      translation:"taskTemplates.add_number"
    },
    {//decimal
      type: 'decimal',
      icon:'numbers',
      translation:"taskTemplates.add_decimal"
    },
    {//currency
      type: 'currency',
      icon:'euro',
      translation:"taskTemplates.add_currency"
    },
    {//dateTime
      type: 'dateTime',
      icon:'calendar_month',
      translation:"taskTemplates.add_date"
    },
    {//boolean
      type: 'boolean',
      icon:'toggle_on',
      translation:"taskTemplates.add_switch"
    },
    {//options
      type: 'options',
      icon:'list',
      translation:"taskTemplates.add_list"
    },
    {//weight
      type: 'weight',
      icon:'weight',
      translation:"taskTemplates.add_weight"
    },
    {//tridy
      type: 'tridy',
      icon:'contactless',
      translation:"taskTemplates.add_tridy"
    },
    {//barcode
      type: 'barcode',
      icon:'qr_code_scanner',
      translation:"taskTemplates.add_barcode"
    },
    {//signature
      type: 'signature',
      icon:'signature',
      translation:"taskTemplates.add_signature"
    },
    {//document
      type: 'document',
      icon:'attach_file',
      translation:"taskTemplates.add_doc"
    },
    {//nfc
      type: 'nfc',
      icon:'nfc',
      translation:"taskTemplates.add_nfc"
    },
    {//showText
      type: 'showText',
      icon:'edit_note',
      translation:"taskTemplates.add_showText"
    },
    {//showImage
      type: 'showImage',
      icon:'image',
      translation:"taskTemplates.add_showImage"
    }
  ]
  constructor(private router: Router,
    private route: ActivatedRoute,
    private settingService: SettingsService,
    private fb: FormBuilder,
    public dialog: NgDialogAnimationService,
    private commentService: CommentsService,
    private worktasktemplateService: WorkTaskTemplatesService,
    private translate:TranslateService
    ){
    this.routerUrl=this.route.url
    this.idToShow=this.routerUrl.value[this.routerUrl.value.length-1].path 

     this.translate.get([
      'taskTemplates.add_display',
      'taskTemplates.add_hidden',
      'taskTemplates.add_text',
      'taskTemplates.add_longText',
      'taskTemplates.add_number',
      'taskTemplates.add_decimal',
      'taskTemplates.add_currency',
      'taskTemplates.add_date',
      'taskTemplates.add_dateTime',
      'taskTemplates.add_time',
      'taskTemplates.add_switch',
      'taskTemplates.add_list',
      'taskTemplates.add_weight',
      'taskTemplates.add_tridy',
      'taskTemplates.add_barcode',
      'taskTemplates.add_signature',
      'taskTemplates.add_doc',
      'taskTemplates.add_nfc',
      'taskTemplates.add_showText',
      'taskTemplates.add_showImage'
    ]).subscribe(translations => {
      Object.keys(translations).forEach(key => {
        const fieldType = key.replace('taskTemplates.add_', '');
        this.fieldTypeTitles[fieldType] = translations[key];
      });
    });
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.changesToSave) {
      return this.openCancelChangesDialog();
    } else {
      return true; // Allow navigation if no changes
    }
  }

  private openCancelChangesDialog(): Observable<boolean> {
    const dialogRef = this.dialog.open(CancelChangesDialogComponent, {
      data: 'takTemplates'
    });

    return dialogRef.afterClosed();
  }

  async ngOnInit():Promise<void>{

    // Stop reload event if there are unsaved changes
    window.onbeforeunload = (event) => {
      if (this.changesToSave && !this.changesCanceled) {
        // Display a confirmation message
        event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
      }
    };

    this.getContent()
    this.tasktemplateUserRights_update = this.settingService.userRolesRights$['workTaskTemplates']['U']
    this.tasktemplateUserRights_delete = this.settingService.userRolesRights$['workTaskTemplates']['D']

    if(!this.tasktemplateUserRights_delete){
      document.getElementById("btn-delete").style.color='#e0e0e0'
    }

    if(!this.tasktemplateUserRights_update){
      document.getElementById("btn-cancel").style.color='#e0e0e0'
      document.getElementById("btn-save").style.backgroundColor='#e0e0e0'
    }

    if(this.settingService.settingsContentTypesWithId.length==0){
      try{
        this.commentService.progressSpin.emit(true)
        this.settingService.observeContextsContentTypesFirestoreWithId(this.settingService.contextId$)
        this.allContentTypesList = this.settingService.settingsContentTypesWithId
        this.commentService.progressSpin.emit(false)

      }catch(error){
        this.commentService.progressSpin.emit(false)
        const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
        this.commentService.addSnackBar.emit(message)
      }
    }else{
      this.allContentTypesList = this.settingService.settingsContentTypesWithId
    }

    if(this.settingService.settingsWorkTaskTemplatesGroups.length!=0){
      this.taskTemplateGroups = this.settingService.settingsWorkTaskTemplatesGroups
    }else{
      this.getTasksTemplatesGroups()
    }

    this.taskTemplateForm = this.fb.group({
      actions: [],
      groupTitle:[],
      contentType: [],
      formSections: [],
      id:[],
      sortIndex: [],
      title: [],
      tridySource: [],
      visibleInPass: [],
      predicates: [],
      titleKeys:[],
      incomingTridys:[],
      outgoingTridys:[],
      taskType:[],
      groupId:[],
      showImages:[],
      showComment:[],
      hidden:[],
      gpsMandatory:[],
    })

    this.taskTemplateForm.get('formSections').valueChanges.subscribe(value => {
      this.checkDuplicatesKeys()
    })

    this.taskTemplateForm.get('contentType').valueChanges.subscribe(value => {
      if(value)
        this.missingContentType = false
      this.selectedContentType = value
      this.checkDuplicatesKeys()
    })
  }

  drop(event: CdkDragDrop<string[]>, index:number) {

    moveItemInArray(this.sections[index].fields, event.previousIndex, event.currentIndex);
    this.taskTemplateForm.patchValue({formSections: this.sections})
  }

  dropPanel(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.sections, event.previousIndex, event.currentIndex);
    this.taskTemplateForm.patchValue({ formSections: this.sections });
  }

  async getContent(){

    try{
      this.commentService.progressSpin.emit(true)
      this.taskTemplate = await this.settingService.observeContextsWorkTasksTemplatesFirestore(this.idToShow)
      this.convertDisplayFields()
      this.oldData = deepCopy(this.taskTemplate)
      this.initializeForm()
      this.commentService.progressSpin.emit(false)
    }catch(error){
      this.commentService.progressSpin.emit(false)
    }
  }

  convertDisplayFields(){
    this.taskTemplate.formSections.map(section => {
      section.fields.map(field => {
        if(field.type == "display"){
          //console.log(field)
          field.type = "text"
          field.readOnly = true
          //console.log(field)
        }
      })
    })
  }
  
  getTasksTemplatesGroups(){
    try{
      this.commentService.progressSpin.emit(true)
      this.taskTemplateGroups = this.settingService.observeContextsWorkTasksTemplatesGroupsFirestoreWithId()
      if(this.taskTemplateGroups.length==0){
        this.emptyList=true
      }      
      this.commentService.progressSpin.emit(false)
  
    }catch(error){
      this.commentService.progressSpin.emit(false)
      const message = this.translate.instant("SnackBarConstants.LOAD_FAILED")
      this.commentService.addSnackBar.emit(message)
    }
  }

  initializeForm(){
    const { actions, 
            groupTitle, 
            contentType,
            formSections,
            id,
            sortIndex,
            titleKeys,
            title,
            tridySource,
            visibleInPass,
            predicates,
            incomingTridys,
            outgoingTridys,taskType,
            groupId,
            showImages,
            showComment,
            hidden,
            gpsMandatory,
          } = this.taskTemplate

    // Find the group with the matching ID
    const matchingGroup = this.taskTemplateGroups.find(group => group.id === groupId);
    this.taskTemplateGroupName = matchingGroup ? matchingGroup.title : undefined;

    this.taskTemplateForm.patchValue({
      groupTitle: groupTitle ? groupTitle : undefined,
      actions: actions ? actions : undefined,
      contentType: contentType ? contentType : undefined,
      formSections: formSections ? formSections : undefined,
      id: id ? id : undefined,
      sortIndex: sortIndex ? sortIndex : undefined,
      title: title ? title : undefined,
      tridySource: tridySource ? tridySource : undefined,
      visibleInPass: visibleInPass ? visibleInPass : false,
      predicates: predicates ? predicates : undefined,
      titleKeys: titleKeys ? titleKeys : undefined,
      incomingTridys : incomingTridys ? incomingTridys : undefined,
      outgoingTridys: outgoingTridys ? outgoingTridys :undefined,
      taskType: taskType ? taskType : undefined,
      groupId: groupId ? groupId : undefined,
      showImages:showImages, //? showImages : undefined,
      showComment:showComment, //? showComment : undefined,
      hidden: hidden ? hidden : undefined,
      gpsMandatory: gpsMandatory ? gpsMandatory : undefined,

    })
    this.selectedContentType = contentType

    if(!contentType)
    this.missingContentType = true

    this.taskTemplateForm.valueChanges.subscribe( (value:any)=>{
      this.changesToSave=true
    })


    if(this.taskTemplateForm.value.title){
      this.titleTask = this.taskTemplateForm.value.title
    }

    if(this.taskTemplateForm.value.formSections){
      this.sections = deepCopy(this.taskTemplateForm.value.formSections)
      this.checkDuplicatesKeys()
    }
  }

  backTo(){
    this.router.navigate(['home', this.settingService.contextId$,'taskTemplates'])
  }

  saveTaskTemplate(){

    if(!this.duplicateKeys){
      try{
        this.commentService.progressSpin.emit(true)
        const data = removeUndefinedValuesFromObject(this.taskTemplateForm.value)
        if( this.taskTemplate.groupId){
          if(!data['groupId']){
            data['groupId'] = deleteField()
          }
        }
        this.worktasktemplateService.updateWorkTaskTemplate(data, this.idToShow)
        this.commentService.progressSpin.emit(false)
        this.titleTask = data.title
        this.changesToSave = false
        this.oldData = deepCopy(data)
        this.taskTemplate = deepCopy(data)
        const message = this.translate.instant("SnackBarConstants.UPDATE_OK")
        this.commentService.addSnackBar.emit(message)
      }catch(error){
        console.log(error)
        const message = this.translate.instant("SnackBarConstants.UPDATE_FAILED")
        this.commentService.addSnackBar.emit(message)
      }
    }else{
      let dialogRef= this.dialog.open(ErrorDialogComponent, {
        data: { 
           message: 'taskTemplates.unique_keys'
        },
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result[1]){
          
        }
      });
    }
    
  }

  addSection(){
    const defaultTitle = this.translate.instant("workTask.newSection");
    const newSection = {
      fields:[],
      title: defaultTitle,
      titles: {
        de: "Neuer Abschnitt",
        en: "New section",
        es: "Nueva sección"
      }
    }

    this.sections.push(newSection)
    this.taskTemplateForm.patchValue({formSections: this.sections})
    
  }

  deleteSection(title, index){
    this.sections.splice(index,1)
    this.taskTemplateForm.patchValue({formSections: this.sections})
  }

  duplicateSection(section, index){
    const newSection = deepCopy(section)

    if(newSection.fields){
      newSection.fields.forEach((field, index) => {
        field.id = this.makeid(6)
        this.allIds = field.id
        field.key += '_copy'
        this.allKeys.push(field.key)
      });
    }
    newSection.title += '_copy'
    this.sections.push(newSection)
    this.taskTemplateForm.patchValue({formSections: this.sections})

  }

  makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    if(this.allIds.includes(result)){
      result = this.makeid(6)
    }
    return result;
}

  addFieldToSection(fieldType, section){
    const id= this.makeid(6)
    const _defaultFields = defaultFields
    if(!this.allIds.includes(id)){
      this.allIds.push(id)
    }
    const newField = deepCopy(_defaultFields[fieldType])
    newField['id']=id
    const fieldTitle = this.fieldTypeTitles[fieldType] || 'New Field';
    newField['title'] = `${fieldTitle} ${++this.addedFields}`;
    newField['key']+=''+this.addedFields

    if(fieldType=='tridy'){
      if (this.taskTemplateForm.value.contentType){
        newField['contentType'] = this.taskTemplateForm.value.contentType
      }
    }
    if(this.allKeys.includes(newField['key'])){
      const random = this.makeid(4)
      newField['key']+=''+ random
      newField['title']+=''+random
    }
    this.addedFields++;
    if(this.sections && this.sections[section] && this.sections[section].fields){
      this.sections[section].fields.push(newField)
      this.allKeys.push(newField.key)
      this.taskTemplateForm.patchValue({formSections: this.sections})
    }
  }

  deleteFieldOfSection(fieldNumber, index){
    this.sections[index].fields.splice(fieldNumber,1)
    this.taskTemplateForm.patchValue({formSections: this.sections})
  }

  editTitleSection(title, index){

    let dialogRef= this.dialog.open(TaskTemplateEditSectionDialogComponent, {
      data: { 
         values: title
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        this.sections[index].title = result[0].title
        this.sections[index].titles['de'] = result[0].title
        this.taskTemplateForm.patchValue({formSections: this.sections})
      }
    });

  }

  openWorkTaskTemplateDialog(){
      let dialogRef = this.dialog.open(WorkTaskTemplateDialogComponent, {
      data: { 
         values: this.taskTemplateForm.value,
         groups: this.taskTemplateGroups
      },
      panelClass: 'custom-dialog-container',
      position: { right: '0'},
      animation: {
        to: "left",
        incomingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        },
        outgoingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        this.taskTemplateForm.patchValue(result[0])

        if(result[0].groupId){
          const groupId = this.taskTemplateForm.value['groupId']
          const matchingGroup = this.taskTemplateGroups.find(group => group.id === groupId);
          this.taskTemplateGroupName = matchingGroup ? matchingGroup.title : undefined;
        }else{
          if(this.taskTemplateGroupName){
            this.taskTemplateGroupName=""
          }
        }
      }
    });
  }

  openWorkTaskTemplateAdvancedDialog(){

    let dialogRef = this.dialog.open(WorkTaskTemplateAdvancedDialogComponent, {
      data: { 
         values: this.taskTemplateForm.value
      },
      panelClass: 'custom-dialog-container',
      position: { right: '0'},
      animation: {
        to: "left",
        incomingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        },
        outgoingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result[1]){
        this.taskTemplateForm.patchValue(result[0])
      }
    });
  }

  openFieldDialog(item, fieldNumber,index){
    let dialogRef = this.dialog.open(TaskTemplateFieldDialogComponent, {
    data: { 
       values: item,
       taskId: this.idToShow,
       fieldNumber:fieldNumber,
       sectionNumber: index
    },
    panelClass: 'custom-dialog-container',
      position: { right: '0'},
      animation: {
        to: "left",
        incomingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        },
        outgoingOptions: {
          keyframeAnimationOptions: { duration: 200, easing: "ease-in-out" }
        }
      }
  });

  dialogRef.afterClosed().subscribe(result => {
   
    if(result[1]){
      
      this.sections[index].fields[fieldNumber]=result[0]
      this.taskTemplateForm.patchValue({formSections: this.sections})

    }
  });
  }

  deleteTask(){
    let dialogRef= this.dialog.open(DeleteConfirmationDialogComponent, {
      data: { 
        values:this.taskTemplateForm.value,
        type:"Task Template"
      },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result[0]){
        this.router.navigate(['home',this.settingService.contextId$,'taskTemplates'])
      }else{
        const message = this.translate.instant("SnackBarConstants.DELETE_DISMISS")
        this.commentService.addSnackBar.emit(message)
      }
    });
  }

  checkDuplicatesKeys(){
    let allFieldsNumber = 0
    this.allIds=[]
    this.allKeys=[]
    let changes = false
    this.duplicateKeys = false

    this.sections.forEach((section, index) => {
      if(section.fields ){
        section.fields.forEach( (field, number) => {
          if(field.id){
            if(this.allIds.includes(field.id)){
              field.id = this.makeid(6)
              changes = true
              this.allIds.push(field.id)
            }else{
              this.allIds.push(field.id)
            }
          }
           
          if(field.key){
            if(field.key.includes(" ")){
              // field.key = field.key.replace(" ", "_")
              // field.key = field.key.replaceAll(" ", "_")
            }
            allFieldsNumber++
            this.addedFields = allFieldsNumber
            if(this.allKeys.includes(field.key)){
              this.duplicateKeys = true
              this.allKeys.push(field.key)
            }else{
              this.allKeys.push(field.key)
            }
          }else{
            if(field.key==""){
              if(this.allKeys.includes(field.key)){
                this.duplicateKeys = true
                this.allKeys.push("")
              }else{
                this.allKeys.push("")
              }
            }
          }

          if(field.payloadKey){
            if(field.payloadKey.includes(" ")){
              // field.payloadKey = field.payloadKey.replace(" ", "_")
              // field.payloadKey = field.payloadKey.replaceAll(" ", "_")
            }
          }

          if(this.selectedContentType && field.type=='tridy'){///---this prevents tridy fields without contentType
            if(!field.contentType){
              field.contentType = this.selectedContentType
              this.taskTemplateForm.patchValue({formSections : this.sections})
            }
          }
        })
      }
    });

    if(changes){
      this.taskTemplateForm.patchValue({formSections : this.sections})
    }
  }

  isTime(value){
    return isTime(value)
  }
  getContentTypeIdName(id){
    const content = this.allContentTypesList.find( contentType => contentType.id == id )
    if(content)
    return content.displayName
  }

  includesSource(source, value){
    if(source && source.includes(value))
    return true
    else
    return false
  }

  transformMarkDownText(item){
    let texttransformed
    if(item.value){
      
      let markdown = new MarkdownIt({ breaks: true });
      let text = markdown.render(item.value);
      texttransformed = text;
    }
    return texttransformed
  }
  
  loadShowImage(defaultValue){
    let url = supportUrlStructure(defaultValue, this.idToShow, this.settingService.contextId$)
    return url
  }
}
