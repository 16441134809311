import { User } from "./user.dto";
import { Widgets } from "./widgets.dto";

export interface ILightColors{
    accent?:string
    background?:string
    primary?:string
    secondary?:string
    subtitle?:string
    title?:string
}

export interface IColors{
    accent?:string
    background?:string
    primary?:string
    secondary?:string
    subtitle?:string
    title?:string
}

export interface IFonts{
    italic?:string
    bold?:string
    plain?:string
    subtitle?:string
    title?:string
}

export interface ITheme{
    lightColors?: ILightColors
    darkColors?: IColors
    fonts?:IFonts
    cornerRadius:number
    widgetSpacing:number
}

export interface ITemplate{
    widgets?:object[]
    id:string
    creationDate?:Date
    contextId:string
    timeStamp?:Date
    theme?:ITheme
    comment?:string
    name?:User
    contentType?:string
    customer?:User
    tridyId?:string
    translations?:string[],
    languages?:string[]
}

export class Template{
    id: string|undefined = undefined
    creationDate: Date|undefined = undefined
    contextId: string|undefined = undefined
    timeStamp:Date|undefined = undefined
    theme: ITheme|undefined = undefined
    comment: string|undefined = undefined
    name: User|undefined = undefined
    contentType: string|undefined = undefined
    customer: User|undefined = undefined
    widgets: object[]|undefined = undefined
    tridyId:string|undefined = undefined
    translations:string[]|undefined = undefined
    languages:string[]|undefined=undefined
    enableShoppingCart: boolean| undefined = false
    enableCustomerLogin: boolean|undefined = false

    constructor(){

    }

    setFromAny(element:any){
        const {id, creationDate, contextId, timeStamp, theme, comment, name, contentType, customer, tridyId, widgets, translations,languages,enableShoppingCart,enableCustomerLogin} = element

        this.id = id ? id : this.id
        this.creationDate = creationDate ? creationDate : this.creationDate
        this.contextId = contextId ? contextId : this.contextId
        this.timeStamp = timeStamp ? timeStamp : this.timeStamp
        this.theme = theme ? theme : this.theme
        this.comment = comment ? comment : this.comment
        this.name = name ? name : this.name
        this.contentType = contentType ? contentType : this.contentType
        this.customer = customer ? customer : this.customer
        this.widgets = widgets ? widgets : this.widgets
        this.tridyId = tridyId ? tridyId : this.tridyId
        this.translations = translations ? translations : this.translations
        this.languages = languages ? languages : this.languages
        this.enableShoppingCart = enableShoppingCart ? enableShoppingCart : this.enableShoppingCart
        this.enableCustomerLogin = enableCustomerLogin ? enableCustomerLogin : this.enableCustomerLogin
    }

    getId(){
        if(!this.id) return undefined

        return this.id
    }

    getWidgets(){
        if(!this.widgets) return undefined

        return this.widgets
    }

    getContentType(){
        if(!this.contentType) return undefined

        return this.contentType
    }
}