<h2 mat-dialog-title>{{'advancedSettings' | translate}}</h2>

<mat-dialog-content style="width:450px;">
    <div class="pt-2 pb-3">
        <section class="mt-1 mb-3 rounded m-auto w-100" id="section" >
            <form [formGroup]="passEditForm" class="m-1">
                <label class="mb-3 text-muted fs-6">{{'general' | translate}}</label>
        
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>{{'pass.comment' | translate}}</mat-label>
                    <input formControlName="comment" type="text" matInput placeholder="{{'pass.comment' | translate}}">
                </mat-form-field>

                <div class="p-3 form-control mb-3 d-flex justify-content-between">
                    <label for="value">{{'pass.enableShoppingCart' | translate}}</label>
                    <mat-slide-toggle class="example-margin" formControlName="enableShoppingCart" ></mat-slide-toggle>  
                </div>

                <div class="p-3 form-control mb-1 d-flex justify-content-between">
                    <label for="value">{{'pass.enableCustomerLogin' | translate}}</label>
                    <mat-slide-toggle class="example-margin" formControlName="enableCustomerLogin" ></mat-slide-toggle>  
                </div>
                
            <!-- languages  -->
                 <div class="my-3">
                    <div class="d-flex flex-row flex-wrap align-items-center">
                        <label class="my-3 text-muted fs-6 text-uppercase" >{{'pass.languages' | translate}}</label>
                    </div>
                    <div class="form-group my-2">
                        <div class="list-group" cdkDropList (cdkDropListDropped)="dropLanguage($event)">
                            <div *ngFor="let lang of languagesPass; let index= index; "  class="p-1 list-group-item">
                                <div cdkDrag class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                    <div class="ps-2">
                                        <div *ngIf="index==0"><span class="fw-bold">{{languagesList[lang]}}</span> <span class="hint-text">{{'defaultLanguage' | translate}}</span></div>
                                        <div *ngIf="index!=0">{{languagesList[lang]}}</div>
                                    </div>  
                                    <button mat-icon-button style="color:red;" (click)="deleteLanguageOfPass(lang,index);">
                                        <span class="material-symbols-outlined">
                                            delete
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="languagesPass.length==0" class="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                <p> {{'empty' | translate}}...</p>
                                <p style="font-size:14px;color:var(--warmOrange);">{{'languages_hint' | translate}}</p>
                            </div>
                        </div>
                        <button mat-button class="secondary-gray-button mt-3" [matMenuTriggerFor]="listLanguages">
                            <mat-icon>add_circle</mat-icon> {{'add' | translate}}
                        </button>
                        <mat-menu #listLanguages="matMenu">
                            <button mat-menu-item *ngFor="let lang of languages; let index = index;" (click)="addLanguageToPass(lang[0]);" [disabled]="languagesIsAdded(lang[0])"> {{lang[1]}} </button>
                        </mat-menu>
                    </div>
                </div> 

            </form>
        </section>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button style="color:var(--warmOrange);" (click)="onCancelEdit();" > {{'cancel' | translate}} </button>
    <button mat-button class="orange-button" (click)="onSaveEdit();"  >{{'apply' | translate}}</button>
</mat-dialog-actions>